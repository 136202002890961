<template>

  <div class="newsletter-area">
    <div class="">
      <div class="row">
        <div class="col-lg-1 col-md-3">
          <div class="newsletter-content newsletter-content-line">
            <h3>{{ LastNews }}</h3>
          </div>
        </div>
        <div class="col-lg-11 col-md-9">
          <!-- <div class="container"> -->
                <div class="hwrap">
                  <div class="hmove">
                    <div
                      class="hitem"
                      v-for="newstape in NewsTape"
                      :key="newstape.postId"
                    >
                      {{ newstape.postTitle }}
                      <img
                        width="20"
                        height="20"
                        alt=""
                        style="margin: 0 5px"
                        loading="lazy"
                        src="/img/favicon.png"
                      />
                    </div>
                  </div>
                </div>
          <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
</template>
<script>
import axios from "axios";
import ArJson from "../../../public/i18n/Ar.json";
import EnJson from "../../../public/i18n/En.json";

export default {
  data() {
    return {
      showNewsLine: true,
      NewsTape: [],
      direction: "right",
      lang: "",
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
    };
  },
  mounted() {
    var self_ = this;
    if (localStorage.getItem("lang") == null) {
      self_.getApis();
      axios({
        method: "get",
        url: "https://api2.yg-u.net/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
      });
    } else self_.getApis();
  },
  methods: {
    getApis() {
      window.scroll(0, 0);
      var self = this;

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
        self.direction = "right";
      } else {
        self.translate = self.EnTranslat;
        self.direction = "left";
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.LastNews = self.translate[0]["Home"]["LastNews"];

      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPost");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.Home.NewsLine");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "1000");
      axios({
        method: "post",
        url: "https://api2.yg-u.net/our_team/getPost",
        data: bodyFormData,
      })
        .then(function (response) {
          self.NewsTape = response.data;

          var arr = response.data;

          if (arr.length != 0) {
            self.showNewsLine = true;
          } else {
            self.showNewsLine = false;
          }

          setTimeout(() => {
            self.show = false;
          }, 100);
        })
        .catch(function (response) {
            // eslint-disable-next-line no-console
          console.log("error", response);
        });
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {.newsletter-content {
    display: none;
  }
}
.newsletter-area {
  background: #3498db none repeat scroll 0 0;
  color: #ffffff;
  position: relative;
  border-radius: 0 !important;
}
.newsletter-content {
  padding-top: 5px;
  z-index: 9;
  position: relative;
  color: #000;
}
.newsletter-content h3 {
  font-family: inherit;
  font-weight: 700;
  font-size: 18px;
  padding: 6px;
  width: max-content;
  margin-bottom: 2px;
}

.newsletter-two .newsletter-content {
  padding-top: 31px;
}
.hwrap {
  bottom: 0px !important;
}
.hwrap {
  overflow: hidden;
  position: relative;
  bottom: 10px;
}

.hitem {
  flex-shrink: 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
  font-family: 'Droid Arabic Kufi';
}

.hmove:hover {
  animation-play-state: paused;
}
.hmove {
  display: flex;
  padding: 10px 0;
}
/* Large desktop :1920px. */
@media (min-width: 1920px) {
  .newsletter-area::before {
    width: 46.5%;
  }
}

/* Normal desktop :992px. */
@media (min-width: 992px) and (max-width: 1169px) {
  .newsletter-area::before {
    width: 42%;
  }
}
/* Tablet desktop :768px. */
@media (min-width: 768px) and (max-width: 991px) {
  .newsletter-content h3 {
    font-size: 21px;
  }
  .newsletter-content h2 {
    font-size: 25px;
  }
}
/* small mobile :320px. */
@media (max-width: 767px) {
  /* .newsletter-area::before {
    width: 69%;
  } */
  .newsletter-content > h2 {
    font-size: 22px;
  }
}

</style>
