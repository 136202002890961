<template>
  <div>
    <div class="testimonial-area">
      <div class="container">
        <div class="row">
          <div class="col-lg-12 offset-lg-0 col-md-12 col-12">
            <div class="row">
              <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <div class="testimonial-image-slider text-center" style="direction:ltr">
                  <div
                    class="sin-testiImage"
                    v-for="testimonials in Testimonials"
                    :key="testimonials.postId"
                  >
                    <img
                      v-bind:src="
                        'https://api2.yg-u.net/images/post/' +
                        testimonials.postImage
                      "
                      alt="{{testimonials.postTitle}}"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="testimonial-text-slider text-center" style="direction:ltr">
              <div
                class="sin-testiText"
                v-for="testimonials in Testimonials"
                :key="testimonials.postId"
              >
                <h2>{{ testimonials.postTitle }}</h2>
                <p>
                  {{ testimonials.postSubTitle }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ArJson from "../../../public/i18n/Ar.json";
import EnJson from "../../../public/i18n/En.json";

export default {
  data() {
    return {
      showTestimonials: true,
      Testimonials: [],
      direction: "right",
      lang: "",
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
    };
  },
  mounted() {
    var self_ = this;
    if (localStorage.getItem("lang") == null) {
      self_.getApis();
      axios({
        method: "get",
        url: "https://api2.yg-u.net/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
      });
    } else self_.getApis();
  },
  methods: {
    getApis() {
      window.scroll(0, 0);
      var self = this;

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
        self.direction = "right";
      } else {
        self.translate = self.EnTranslat;
        self.direction = "left";
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.LastNews = self.translate[0]["Home"]["LastNews"];

      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPost");
      bodyFormData.append("PostId", "");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.Home.Sec.Testimonials");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "6");
      axios({
        method: "post",
        url: "https://api2.yg-u.net/our_team/getPost",
        data: bodyFormData,
      })
        .then(function (response) {
          self.Testimonials = response.data;

          var arr = response.data;

          if (arr.length != 0) {
            self.showTestimonials = true;
          } else {
            self.showTestimonials = false;
          }
          document.getElementById("VueMainJsNewTouch")?.remove();
          let recaptchaScript = document.createElement("script");
          recaptchaScript.setAttribute("src", "/js/main.js");
          recaptchaScript.id = "VueMainJsNewTouch";
          document.head.appendChild(recaptchaScript);
          setTimeout(() => {
            self.show = false;
          }, 100);
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
    },
  },
};
</script>

<style scoped>
.testimonial-area .container .row {
  flex-direction: column;
}
</style>
