<template>
  <div
    v-show="showKnowUniv"
    class="latest-area section-padding"
    style="background-color: #3498db; padding: 60px 0 67px"
  >
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-title-wrapper">
            <div class="section-title">
              <h3 style="margin-bottom: 24px; color: #fff">{{ AboutUs }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          v-for="KnowUniv in KnowUniv"
          :key="KnowUniv.postId"
          class="col-lg-4 col-md-6"
        >
          <div class="single-event-item">
            <div class="single-event-image">
              <a href="javascript:void(0)">
                <img
                  v-bind:src="
                    'https://api2.yg-u.net/images/post/' +
                    KnowUniv.postImage
                  "
                />
              </a>
            </div>
            <div class="single-event-text">
              <h6 style="margin-bottom: 10px">
                <a href="javascript:void(0)">{{ KnowUniv.postTitle }}</a>
              </h6>
              <p>{{ KnowUniv.postSubTitle }}</p>

              <router-link
                class="button-default"
                :to="{
                  name: 'Univ-with-id-And-Type',
                  params: {
                    type: 'Univ.Home.Sec.KnowUniv',
                    id: KnowUniv.postID,
                  },
                }"
              >
                {{ Detials }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-sm-12 text-center">
        <router-link
          :to="{
            name: 'Univ-with-Type',
            params: {
              type: 'Univ.Home.Sec.KnowUniv',
            },
          }"
          class="button-default button-large"
        >
          {{ BtnMore }} <i class="fa " :class="{ 'fa-chevron-left': showHide,'fa-chevron-right':!showHide }"></i
        ></router-link>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import ArJson from "../../../public/i18n/Ar.json";
import EnJson from "../../../public/i18n/En.json";

export default {
  data() {
    return {
      showKnowUniv: true,
      showHide: true,
      KnowUniv: [],
      AboutUs: "",
      BtnMore: "",
      Detials: "",
      direction: "right",
      lang: "",
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
    };
  },
  mounted() {
    var self_ = this;
    if (localStorage.getItem("lang") == null) {
      self_.getApis();
      axios({
        method: "get",
        url: "https://api2.yg-u.net/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
      });
    } else self_.getApis();
  },
  methods: {
    getApis() {
      window.scroll(0, 0);
      var self = this;

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
        self.direction = "right";
        self.showHide=true;
      } else {
        self.translate = self.EnTranslat;
        self.direction = "left";
        self.showHide=false;
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.AboutUs = self.translate[0]["Home"]["AboutUs"];
      self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
      self.Detials = self.translate[0]["Home"]["Detials"];

      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPost");
      bodyFormData.append("PostId", "");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.Home.Sec.KnowUniv");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "3");
      axios({
        method: "post",
        url: "https://api2.yg-u.net/our_team/getPost",
        data: bodyFormData,
      })
        .then(function (response) {
          self.KnowUniv = response.data;

          var arr = response.data;
          if (arr.length != 0) {
            self.showKnowUniv = true;
          } else {
            self.showKnowUniv = false;
          }

          setTimeout(() => {
            self.show = false;
          }, 100);
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
    },
  },
};
</script>

<style scoped>
.single-event-image span {
  display: block;
  font-family: "montserratsemi_bold";
  font-size: 26px;
  text-align: center;
}
</style>
