<template>
  <div>
    <Loading v-show="show" />
    <div class="slider-area slider-two" v-show="showSlider">
      <div class="preview-2">
          <div class="overly"></div>
        <div id="nivoslider" class="slides">
            <img v-for="slider in sliders" :key="slider.postID"
              v-bind:src="
                'https://api2.yg-u.net/images/post/' + slider.postImage
              "
              v-bind:title="'#'+slider.postID"
            />
        </div>

        <div v-for="slider in sliders" :key="slider.postID">
          <div v-bind:id="slider.postID" class="nivo-html-caption nivo-caption">
            <div class="banner-content slider-1">
              <div class="container">
                <div class="row">
                  <div class="col-md-12">
                    <div class="text-content-wrapper">
                      <div class="text-content">
                        <h1 class="title1">{{ slider.postTitle }}</h1>
                        <p
                          style="text-align: justify"
                          class="sub-title d-none d-lg-block"
                        >
                          {{ slider.postSubTitle }}
                        </p>
                        <div class="banner-readmore">
                          <a
                            :href="slider.url" target="_blank"
                            class="button-default bg-blue"
                            >{{ slider.urlname }}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

<NewsLine/>
<AboutHome/>
<KnowUniv/>
<Facts/>
<HomeNews/>
<HomeSevices/>
<Testmonials/>
<HomeActivity/>

  </div>
</template>

<script>
import Loading from "./Loading";
import NewsLine from "./homeComponents/NewsLine";
import AboutHome from "./homeComponents/AboutHome";
import KnowUniv from "./homeComponents/KnowUniv";
import Facts from "./homeComponents/Facts";
import HomeNews from "./homeComponents/HomeNews";
import HomeSevices from "./homeComponents/HomeSevices";
import Testmonials from "./homeComponents/Testmonials";
import HomeActivity from "./homeComponents/HomeActivity";

import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
    NewsLine,
    AboutHome,
    KnowUniv,
    Facts,
    HomeNews,
    HomeSevices,
    Testmonials,
    HomeActivity,
  },
  data() {
    return {
      show: true,
      showSlider: true,
      sliders: [],
      direction: "right",

      lang: "",
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      Detials: "",
    };
  },
  mounted() {
    var self_ = this;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yg-u.net/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        self_.getApis();
      });
    } else self_.getApis();
  },
  methods: {
    getApis() {
      window.scroll(0, 0);
      var self = this;
      
      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
        self.direction = "right";
      } else {
        self.translate = self.EnTranslat;
        self.direction = "left";
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.Detials = self.translate[0]["Home"]["Detials"];
      
      var bodyFormData = new FormData();
      bodyFormData.append("check", "getPostDetails");
      bodyFormData.append("PostId", "");
      bodyFormData.append("Lang", localStorage.getItem("lang"));
      bodyFormData.append("FbrnId", "");
      bodyFormData.append("Type", "Univ.Home.MainSlider");
      bodyFormData.append("ParentId", "NTROOT0");
      bodyFormData.append("PostName", "");
      bodyFormData.append("Pno", "-1");
      axios({
        method: "post",
        url: "https://api2.yg-u.net/our_team/getPost",
        data: bodyFormData,
      })
        .then(function (response) {
          var arr = response.data;

          if (arr.length == 0) self.showSlider = false;
          else self.showSlider = true;

          self.sliders = response.data;

          // document.getElementById("VueMainJsNewTouch")?.remove();
          // let recaptchaScript = document.createElement("script");
          // recaptchaScript.setAttribute("src", "/js/main.js");
          // recaptchaScript.id = "VueMainJsNewTouch";
          // document.head.appendChild(recaptchaScript);
          setTimeout(() => {
            self.show = false;
            document.getElementById('YG_U').setAttribute('style','display:none !important')

          }, 100);
        })
        .catch(function (response) {
          // eslint-disable-next-line no-console
          console.log("error", response);
        });
    },
  },
};
</script>

<style scoped>
.slider-area {
  z-index: 0;
}
.banner-content h1{
  font-size: 35px;
}
@media (max-width: 767px) {
  .banner-content h1{
  font-size: 15px;
}
}
.slider-two .banner-content p{
  font-size: 20px;
  line-height: 40px;
}
</style>
